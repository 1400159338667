@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: 'Inter', sans-serif;
}

*:focus {
  outline: none;
}

html {
  overflow-y: scroll;
  background-color: #FAFAFA;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.contentHolder {
  max-width: 1200px;
  margin:auto;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}

select::-ms-expand {
  display: none;
}

.app {
  margin-top: 30px;
  margin-left: 64px;
  margin-right: 64px;
}

.title-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  user-select: none;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  margin: 0px;
}

.title-row h1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.title-row svg {
  margin-bottom: 10px;
  margin-left: 15px;
}

.login {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
}

.navBar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-top: 3px solid black;
  border-bottom: 3px solid black;
  min-height: 82px;
  flex-wrap: wrap;

  margin-bottom: 38px;
}

.navBar div {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 64px;
  user-select: none;

}

.login.selected,
.navBar div.selected {
  color: red;
}

.mainSplit {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.box {
  box-sizing: border-box;

  width: 100%;
  border: 3px solid #000000;
  margin-bottom: 48px;
}

.half {
  width: calc(50% - 20px);
}

.full {
  width: 100%;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  margin: 0px;
  margin-left: 16px;
}

h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  margin: 0px;
  margin-left: 16px;
}

.seriesResultsContent h4,
.driverContent h4,
.raceContent h4,
.seriesContent h4 {
  margin-bottom: 35px;
}

.closeButton {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;
  margin: 0px;
}

.seriesResultsContent .closeButton,
.driverContent .closeButton,
.raceContent .closeButton,
.seriesContent .closeButton {
  text-align: right;
  margin-right: 3px;
  margin-top: 3px;
}

.signInContent h3,
.resultsQuery h3,
.driverResultForm h3,
.newDriverContent h3,
.newRaceContent h3,
.newSeriesContent h3 {
  margin-top: 27px;
}

.resultsQuery form label,
.driverContent form label,
.raceContent form label,
.newRaceContent form label,
.newSeriesContent form label {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.resultsQuery form,
.driverResultForm form,
.driverContent form,
.raceContent form,
.newRaceContent form,
.newSeriesContent form {
  margin-left: 16px;
  margin-top: 8px;
}

.resultsQuery .selectDropdown {
  margin-left: 16px;
  margin-bottom: 20px;
}

.driverContent form,
.raceContent form {
  margin-bottom: 20px;
}

form input {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border: none;
  margin-left: 4px;
}

form input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border: none;
  color: black;
  font-style:italic;
}

button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  background-color: unset;
  border: none;
  cursor: pointer;
}

.box button {
  margin-top: 16px;
  margin-left: 8px;
  margin-bottom: 8px;
}

.clickable:hover {
  color: red;
  cursor: pointer;
  user-select: none;
}

.driverResultForm select {
  padding: 1px 2px;
  margin-left: 4px;
}

.driverResultForm form {
  margin-bottom: 0px;
}

.driverResultForm .createButton {
  margin-top: 0px;
}

input {
  background-color: #FAFAFA;
}

.selectDropdown div div div:first-child {
  background-color: #FAFAFA;
}

.selectDropdown,
.selectDropdown span,
.selectDropdown span:hover,
.selectDropdown span:focus,
.selectDropdown div,
.selectDropdown div:hover,
.selectDropdown div:focus {
  border: 0px;
  border-radius: 0px;
  color: black !important;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  padding: 0px;
  min-height: unset;
}

.selectDropdown div {
  margin: 0px;
}

.selectDropdown *:focus,
.selectDropdown *:hover {
  border: 0px !important;
  outline: 0px !important;
}

.selectDropdown div {
  width: 100%;
  min-width: 120px;
}

.seriesResultsContent .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 16px;
  margin-right: 16px;
}

.seriesResultsContent .row * {
  margin-left: 0px;
  margin-right: 0px;
}

footer {
  font-size: 10px;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.hidden {
  pointer-events: none;
  opacity: 0;
}

.seriesResultsContent,
.raceResults {
  margin-bottom: 32px;
}

.seriesResultsContent h4,
.raceResults h4 {
  margin-bottom: 0px;
  user-select: none;
}

.tabulator {
  margin-left: 16px;
  margin-right: 16px;
}

.tabulator *,
.tabulator,
.tabulator .tabulator-header {
  border: 0px !important;
  background-color: unset !important;
  user-select: none;
}

.tabulator .tabulator-header .tabulator-col {
  border: 0px !important;
  background: #FAFAFA !important;
  color: black !important;
}

.tabulator-row .tabulator-cell {
  border: 0px !important;
  color: black !important;
  text-transform: uppercase !important;
}

.tabulator-row {
  cursor: pointer !important;
}

.tabulator-col-title:hover,
.tabulator-row:hover .tabulator-cell {
  color: red !important;
}

.tabulator *,
.tabulator { 
  font-size: 18px !important;
}

.tabulator {
  overflow: auto !important;
}

.tabulator-tableholder,
.tabulator-header {
  overflow: initial !important;
}

.tabulator::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
}


@media (max-width: 648px) {
  .app {
    margin-left: 8px;
    margin-right: 8px;  
  }

  .box {
    box-sizing: border-box;
  
    width: 100%;
  
    border: 3px solid #000000;
    margin-bottom: 48px;
  }

  .mainSplit div {
    width: 100%;
  }

  .half {
    width: 100%;
  }

  .mainSplit div .row {
    width: unset;
    margin-top: 20px;
  }

  .mainSplit div .row.bottom {
    margin-top: 0px;
    margin-bottom: 35px;
  }

  .mainSplit div .row h4,
  .mainSplit div .row h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .selectDropdown {
    width: calc(100% - 16px) !important;
  }

  .selectDropdown div {
    width: 100%;
    min-width: 120px;
  }

  .title-row h1 svg {
    display: none;
  }

  .seriesResultsContent div,
  .raceResults div {
    width: unset;
  }

  .tabulator *,
  .tabulator { 
    font-size: 14px !important;
  }

  .seriesResultsContent,
  .raceResults {
    margin-bottom: 20px;
  }
}